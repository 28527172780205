import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  CardList,
  CarouselSliders,
  DialogDrawer,
  HomepagePayCard,
  NoService,
} from "../../components";
import { DashboardSimmer } from "../../components/simmer/dashboardSimmer";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes, Routes } from "../../router/routes";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Announcement } from "./announcement";
import ExploreCard from "./components/ExploreCard";
import Requests from "./components/Requests";
import DashboardPay from "./dashboardPay";
import HomepageGraph from "./graphDashboard";
import { useStyles } from "./styles";
import { constructOptionObject, enumCatagory, enumSelect, getAccess } from "../../utils";
import KycExpiryCard from "../../components/kycExpiryCard";
import QRCode from "react-qr-code";
import { Avatar, Divider, Grid, Radio, Stack } from "@mui/material";
import PassUnits from "../../assets/passUnits";
import { AgreementNudgeTypes, MaintanenceRequestsNudgeTypes, nudges_card_constants } from "./constant";
import { NudgeCard } from "./components/nudgeCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { format } from "date-fns";
import { OtherFeatures } from "./components/otherFeatues";
import CommunityMemberModel from "../service/components/CommunityMemberModel";

const DashboardMain = ({ props, t = () => false, i18n, handleTopBarState }) => {
  const nudge_slide_settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    cssEase: "linear",
    arrows: false,
  };
  const size = useWindowDimensions();
  const classes = useStyles({ size });
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const history = useHistory();
  const [qr, setQr] = React.useState(false);
  const [nudges_card_state, set_nudges_card_state] = React.useState([]);
  const [unitSwitcher, setUnitSwitcher] = React.useState(false);
  const [toggleDrawerBottom, setToggleDrawerBottom] = React.useState(false);
  const getWeatherDetails = (latitude, longitude, nudges) => {
    let weather_url = `${config.api_url}${BackendRoutes?.weather_get_current_weather}`;
    let payload = { lat: latitude, lon: longitude, };
    NetworkCall(weather_url,
      NetWorkCallMethods?.post, payload, null, true
    ).then((_) => {
      let res = _?.data?.data;

      let nudge_data = constructNudgeState(nudges);

      let weather_data = {
        type: nudges_card_constants({ t, nudge: _ })?.weather?.type,
        title: nudges_card_constants({ t, nudge: _ })?.weather?.title,
        subject: `${res?.name} - ${parseInt(res?.main?.temp)}°C - ${res?.weather?.[0]?.main}`,
        icon: `https://openweathermap.org/img/w/${res?.weather?.[0]?.icon}.png`,
        background: nudges_card_constants({ t, nudge: _ })?.["weather"]?.background,
        dark_dash_color: nudges_card_constants({ t, nudge: _ })?.["weather"]?.dark_dash_color,
        light_dash_color: nudges_card_constants({ t, nudge: _ })?.["weather"]?.light_dash_color,
        onDismiss: () => {
          localStorage?.setItem(LocalStorageKeys?.weather_nudge_meta_data, JSON?.stringify({ timestamp: new Date(), dismiss: true }));
          set_nudges_card_state(nudge_data);
        },
      };

      set_nudges_card_state([weather_data, ...nudge_data]);
    }).catch((err) => {
      let nudge_data = constructNudgeState(nudges);
      set_nudges_card_state(nudge_data);
      console.log("Error: ", err);
    });
  }

  const constructNudgeState = (nudges) => {
    let notificaiton_nudge_meta_data = JSON?.parse(localStorage?.getItem(LocalStorageKeys?.notificaiton_nudge_meta_data) ?? `{"dismiss":false}`);
    let filtered_nudge = nudges;
    if (notificaiton_nudge_meta_data?.dismiss) {
      filtered_nudge = nudges?.filter?.(({ nudge }) => nudge?.nudge_master_id !== "unread_notification");
    }
    let nudge_data = filtered_nudge?.map?.((_) => {
      let nudge_type = nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.type;
      let meta_data = nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.meta_data;
      return {
        type: nudge_type,
        title: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.title,
        subject: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.subject,
        icon: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.icon,
        image: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.image,
        background: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.background,
        dark_dash_color: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.dark_dash_color,
        light_dash_color: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.light_dash_color,
        onClick: () => {
          if (AgreementNudgeTypes?.includes(nudge_type)) {
            localStorage.setItem(
              LocalStorageKeys.agreement,
              JSON.stringify(_?.nudge?.meta_data)
            );
          }
          if (nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.route) {
            let history_search = "?origin=nudge";
            if (MaintanenceRequestsNudgeTypes?.includes(nudge_type)) {
              history_search =
                history_search +
                "&requestId=" +
                meta_data?.maintenance_id +
                "&requestNo=" +
                meta_data?.maintenance_id +
                "&requestType=Maintenance" +
                "&status=Assigned";
            }
            history.push({
              pathname: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.route,
              search: history_search,
              state: {
                type: nudge_type,
                meta_data: meta_data,
              },
            });
          }
        },
        onClick_text: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.onClick_text,
        onDismiss: async () => {
          if (_?.nudge?.nudge_master_id !== "unread_notification") {
            let payload = { reference_array: [_?.nudge?.reference_id] }
            await NetworkCall(
              `${config.api_url}${BackendRoutes?.nudge_dismiss}`,
              NetWorkCallMethods.post, payload, null, true
            ).then((_) => {
              getNudgeData();
            }).catch((err) => {
              console.log(err);
              getNudgeData();
            });
          } else {
            localStorage?.setItem(LocalStorageKeys?.notificaiton_nudge_meta_data, JSON?.stringify({ timestamp: new Date(), dismiss: true }));
            getNudgeData();
          }
        },
        meta_data: nudges_card_constants({ t, nudge: _ })?.[_?.nudge?.nudge_master_id]?.meta_data,
      }
    })

    return nudge_data;
  }
  const getWeatherData = async (nudges) => {
    await new Promise((resolve, reject) => {
      try {
        let weather_nudge_meta_data = JSON?.parse(localStorage?.getItem(LocalStorageKeys?.weather_nudge_meta_data) ?? `{"dismiss":false}`);
        if (!weather_nudge_meta_data?.dismiss && navigator?.geolocation) {
          navigator?.geolocation?.getCurrentPosition?.(
            (_) => {
              getCoordinates(_, nudges);
            },
            (err) => {
              let nudge_data = constructNudgeState(nudges);
              set_nudges_card_state(nudge_data);
              reject(err);
            });
        } else {
          let nudge_data = constructNudgeState(nudges);
          set_nudges_card_state(nudge_data);
        }
        resolve(true);
      } catch (err) {
        let nudge_data = constructNudgeState(nudges);
        set_nudges_card_state(nudge_data);
        reject(err);
      }
    });
  }
  const getCoordinates = (position, nudges) => {
    getWeatherDetails(position.coords.latitude, position.coords.longitude, nudges);
  }
  const getNudgeData = () => {
    let payload = {
      current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.nudge_list}`,
      NetWorkCallMethods.post, payload, null, true
    ).then((_) => {
      if (localStorage.getItem("device") === "mobile") {
        let position = {
          coords: {
            ...JSON.parse(localStorage.getItem("location"))
          }
        }
        getCoordinates(position, _?.data?.data ?? []);
      } else {
        getWeatherData(_?.data?.data ?? []);

      }
    }).catch((err) => {
      console.log(err);
      getWeatherData([]);
    });
  }
  const initial_units_state = {
    unit: ""
  }

  const otherFeatures = (t) => {
    return [
      {
        icon: "/images/fileManager.png",
        title: t("File Manager"),
        router: Routes.fileManager
      },
      // {
      //   icon: "/images/cctv.png",
      //   title: t("My CCTV"),
      //   onClick: () => setToggleDrawerBottom(true)
      // },
      // {
      //   icon: "/images/panic.png",
      //   title: t("Panic"),
      //   onClick: () => setToggleDrawerBottom(true)
      // },
      {
        icon: "/images/dashboardChat.png",
        title: t("Chat"),
        onClick: () => setToggleDrawerBottom(true)
      }]
  }
  const [data, setData] = React.useState(initial_units_state);
  const [agreement, setAgreement] = React.useState([])
  // const [decoded, setDecoded] = React.useState(null);
  const [enum_value, set_enum_value] = React.useState({
    invoice_charge_type: { array: [], object: {}, }
  })
  const [main, setMain] = React.useState({
    agreements: {},
    invoices: {},
    units: {},
    general: {},
    maintenance: {},
    invoicetotal: {},
    profileImg: "",
    security_request: {},
    unread: false,
    is_kyc_expiring: false
  });
  const [banners, setBanners] = React.useState([]);
  const [payOpen, setpayOpen] = React.useState(false);
  const [load, setLoad] = React.useState(true);
  const [noService, setNoService] = React.useState(false)
  const app = config?.app_x_build === "PG-TE-08" ? "Tenant2" : "Tenant"

  const logo = JSON.parse(localStorage?.getItem("accessType"))?.filter(
    (val) => val?.name === app
  );

  const updateState = (key, value) => {
    setData({ ...data, [key]: value })
  }

  const clickedUnit = (data) => {
    setQr(true)
    setUnitSwitcher(false)
    updateState("unit", data)
  }

  const getBanners = () => {
    let data = {
      tenantId: `${config.tenantid}`,
      company_id: logo?.map((val) => val?.company_id),
      offset: 0,
      type: "Dashboard",
      limit: 1000,
    };
    NetworkCall(
      `${config.api_url}/company-banner/list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      let bannerss = response?.data?.data?.map((item) => item?.assets)?.flat();
      setBanners(bannerss);
    });
  };
  const getAgreement = () => {
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    ).then((response) => {
      if (localStorage.getItem(LocalStorageKeys.is_fresh_login) === "true") {
        if (response?.data?.data?.length > 0) {
          localStorage.setItem(
            LocalStorageKeys.agreement,
            JSON.stringify(response?.data?.data[0])
          );
          localStorage.setItem(LocalStorageKeys.is_fresh_login, false);
        }
      }
      let unitList = []
      response?.data?.data?.map((_) => (
        // eslint-disable-next-line
        _?.agreement_unit?.map((e) => {
          unitList = [...unitList, e]
        })
      ))
      setAgreement(unitList)
      updateState("unit", unitList?.[0])
    });
  }
  React.useEffect(() => {
    getNudgeData();
    if (authToken) {
      getAgreement();
      getBanners();
      getEnum()
    } else {
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, []);

  const getEnum = async () => {
    const result = await enumSelect([enumCatagory?.invoice_charge_type])
    let invoice_charge_type_object = constructOptionObject(result?.invoice_charge_type)
    set_enum_value({
      invoice_charge_type: invoice_charge_type_object,
    })
    getDashboardData(invoice_charge_type_object?.object)
  }

  const getDashboardData = (invoice_charge_type) => {
    NetworkCall(
      `${config.api_url}/dashboard`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    ).then((response) => {
      let data = response?.data?.data;

      localStorage.setItem(
        "userProfileId",
        response?.data?.data?.userProfileId
      );
      localStorage.setItem("contactID", response?.data?.data?.contactId);
      let temp_invoices = {
        ...data?.invoices,
        result: data?.invoices?.result?.map?.((invoice) => {
          return {
            ...invoice,
            invoice_type_label: invoice_charge_type?.[invoice?.invoice_type] ?? ""
          }
        })
      }
      setMain({
        agreements: data?.agreements,
        invoices: temp_invoices,
        units: data?.units,
        general: data?.general,
        maintenance: data?.maintenance,
        profileImg: data?.profileImg,
        security_request: data?.security_request,
        unread: data?.is_unread,
        notification: data?.notifications,
        responseData: response?.data.data,
        is_kyc_expiring: data?.is_kyc_expiring
      });
      handleTopBarState({
        t: t,
        note: true,
        i18n: i18n,
        new: data?.is_unread,
      })
      setLoad(false);
    });
  }

  const favouritData = [
    {
      label_1: t("Unit QR"),
      icon_url: "/images/dashboardIcons/qrcode_icon.svg",
      onClick: () => setQr(true),
      show: true,
    },
    {
      label_1: t("Contacts"),
      icon_url: "/images/dashboardIcons/contact_icon.svg",
      link: "/contactdetails",
      show: getAccess('is_my_contact'),
    },    
    {
      label_1: t("Wallet"),
      icon_url: "/images/dashboardIcons/wallet_icon.svg",
      onClick: () => setToggleDrawerBottom(true),
      show: true,
    },
    {
      label_1: t("Bookings"),
      icon_url: "/images/dashboardIcons/booking_icon.svg",
      link: "/mybooking",
      show: getAccess('is_amenity_booking'),
    },
    // {
    //   label_1: t("Bills"),
    //   icon_url: "/images/dashboardIcons/bills_icon.svg",
    //   link: Routes.dashboard,
    //   noavailable: true,
    //   show: true,
    // },
    {
      label_1: t("Personal"),
      icon_url: "/images/dashboardIcons/personal_icon.svg",
      link: "/revenue",
      show: false,
    },
    {
      label_1: t("feedback"),
      icon_url: "/images/dashboardIcons/feedback_icon.svg",
      link: "/feedback",
      // noavailable: true,
      show: true,
    },
  ];

  const quickLinkClick = (item) => {
    if (item?.onClick) {
      item?.onClick()
    }
    if (item?.noavailable) {
      setNoService(true);
    } else if (item?.type === "method") {
      item?.link();
    }
    else if ([Routes?.amenities, Routes?.requestScreen, Routes?.units].includes(item?.link)) {
      history.push(item?.link + "?Id=" + item?.data);
    } else {
      history.push({
        pathname: item?.link,
        state: {
          dashboard: true,
          main: {
            agreement: item?.data,
          },
        },
      });
    }
  }

  return (
    <>
      {load ? (
        <DashboardSimmer />
      ) : (
        <div className={classes.root}>
          {/* <TopNavBars
            t={t}
            i18n={i18n}
            note={true}
            count="4"
            handleClicknote={() => history.push(Routes.notification)}
            new={main?.unread}
            document={() => history.push(Routes?.fileManager)}
          /> */}
          <div
            style={{
              height: size?.height - 135,
              overflow: "auto",
              marginRight: "-6px",
            }}
          >
            <Box p={2} pb={0}>
              {/* KYC EXPIRY */}
              {main?.is_kyc_expiring &&
                <>
                  <KycExpiryCard />
                  <Box height={"16px"} />
                </>
              }
              <Grid container justifyContent="center">
                <Grid item xs={12} alignItems={"center"}>
                  <Slider {...nudge_slide_settings}>
                    {nudges_card_state?.map((nudge, i) => {
                      return <NudgeCard t={t} nudge={nudge} list={nudges_card_state} card_index={i} />
                    })}
                  </Slider>
                </Grid>
              </Grid>
              <Box height="20px" />
              <Typography className={classes.invoiceTitle} mb={"12px"}>
                {/* {t("dash")} */}
                {t("dashQuickLinks")}
              </Typography>
              <Box height="12px" />
              {/* <IconButtonCard data={favouritData} t={t} /> */}
              <Box className={classes.quickLinkBox}>
                <Grid container spacing={"12px"} py={"12px"}>
                  {
                    favouritData?.map((data) => {
                      return (
                        data?.show &&
                        <Grid item xs={3}>
                          <Stack alignItems={"center"} rowGap={1} onClick={() => quickLinkClick(data)}>
                            <img src={data?.icon_url} alt="" className={classes.quickLinkImg} />
                            <Typography className={classes.quickLinkLabel}>{data?.label_1}</Typography>
                          </Stack>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Box>
              {/* <Box height="20px" /> */}
            </Box>
            {/* Announcement carousel */}
            {main?.notification?.list.length > 0 && (
              <div>
                <Box display="flex" justifyContent={"space-between"} p={2}>
                  <Typography className={classes.invoiceTitle}>{t("Announcements")}</Typography>
                  <Box onClick={() => history.push({ pathname: Routes?.notification, state: { tabValue: "2" } })}>
                    <Typography className={classes.viewAllText}>{t("View All")}</Typography>
                  </Box>
                </Box>
                <Announcement t={t} data={main?.notification} loading={load} />
              </div>
            )}
            <Box p={2} pt={0} mt={1}>
              <Box height="20px" />
              {main?.invoices?.result?.length > 0 &&
                <>
                  <Box className={classes.invoiceContainer}>
                    <Box className={classes.invoiceTitleBlock}>
                      <Typography className={classes.invoiceTitle}>
                        {t("Unpaid Invoices")}{" "}{"("}{main?.invoices?.result?.length > 9 ? main?.invoices?.result?.length : "0" + main?.invoices?.result?.length}{")"}
                      </Typography>
                    </Box>
                    <div className={classes.invoice}>
                      <HomepagePayCard
                        Outstanding_amount={main?.invoices?.totalOutStandingAmount}
                        amount={main?.invoices?.totalOverDueAmount}
                        currency_symbol={
                          main?.invoices?.result?.[0]?.currency_symbol
                        }
                        t={t}
                        onClick={() => setpayOpen(true)}
                      />
                      {main?.invoices?.result?.length > 0 &&
                        <Typography className={classes.invoiceQuickPay}>
                          {t("QUICKPAY")}
                        </Typography>
                      }
                      <CardList
                        main={{
                          invoices: main?.invoices?.result,
                          // units: main?.units?.result,
                          // general: main?.general?.result,
                          // maintenance: main?.maintenance?.result,
                          // invoicetotal: main?.invoices,
                        }}
                        primary={true}
                      />
                    </div>
                  </Box>
                  <Box height="20px" />
                </>
              }
              {/* Carousel Slider Ad */}
              {banners?.length > 0 &&
                <>
                  <CarouselSliders autoScroll={true} data={banners} />
                  <Box height="35px" mb={"-7px"} /> </>
              }
              {(main?.general?.result?.length > 0 || main?.maintenance?.result?.length > 0) &&
                <Requests
                  data={{ "general": main?.general, "maintenance": main?.maintenance }}
                  t={t}
                />
              }
              <Box height="20px" />
              <div className={classes.graph}>
                <HomepageGraph data={main?.responseData} invoice_charge_type={enum_value?.invoice_charge_type?.object} />
              </div>
              <Box height="20px" />
              <Typography className={classes.invoiceTitle} textTransform={"capitalize"}>
                {t("other features")}
              </Typography>
              <Box height="12px" />
              <Grid spacing={1} container>
                {otherFeatures(t).map((item) => {
                  return (
                    <Grid item xs={6}>
                      <OtherFeatures
                        router={item?.router}
                        icon={item?.icon}
                        onClick={item?.onClick}
                        title={item?.title} />
                    </Grid>
                  )
                })}
              </Grid>
              <Box height="10px" />
              <ExploreCard t={t} />
              <Box height="20px" />
            </Box>
          </div>
          {/* dashboard invoice pay */}
          <DashboardPay
            open={payOpen}
            onClose={() => setpayOpen(false)}
            agreementlist={main?.agreements?.result}
            Outstanding_amount={main?.invoices?.totalOverDueAmount}
            amount={main?.invoices?.totalOutStandingAmount}
            currency_symbol={main?.invoices?.result?.[0]?.currency_symbol}
            invoices={main?.invoices?.result?.map((val) => val.id)}
            companyList={main?.invoices?.companyInvoices}
          />
        </div>
      )}
      {/* QR DRAWER */}
      <DialogDrawer
        height={"400px"}
        padding={"0px"}
        open={qr}
        header={t("My QR Code")}
        component={
          <Box p={4} className={classes.qrBox}>
            <Box className={classes.whiteBox}>
              <Box m={1}>
                <Box textAlign={"center"}>
                  <QRCode
                    value={data?.unit?.id}
                    size={200}
                  />
                </Box>
              </Box>
              <div className={classes.unitBox}>
                <Stack direction={"row"} alignItems={"center"}>
                  <Avatar variant="rounded" className={classes.unitAvatar}>
                    <PassUnits />
                  </Avatar>
                  <Box flexGrow={1} sx={{ maxWidth: "62%" }}>
                    <Stack direction={"column"} spacing={"2px"}>
                      <Stack direction={"row"} alignItems={"center"}
                        divider={<Box height={"15px"} width={"1px"} bgcolor={"#4e5a6b80"} sx={{ marginInline: "6px" }} />} >
                        {data?.unit?.name && <Typography className={classes.house} noWrap>{data?.unit?.name}</Typography>}
                        {data?.unit?.unit_type && <Typography className={classes.house} noWrap>{data?.unit?.unit_type}</Typography>}
                      </Stack>
                      <Typography className={classes.units} noWrap>{data?.unit?.property_name}</Typography>
                    </Stack>
                  </Box>
                  <Typography onClick={() => {
                    setUnitSwitcher(true)
                    setQr(false)
                  }} className={classes.change} noWrap>{t("Change")}</Typography>
                </Stack>
              </div>
            </Box>
          </Box>
        }
        onClose={() => setQr(false)} />
      <DialogDrawer
        open={toggleDrawerBottom}
        onClose={() => setToggleDrawerBottom(false)}
        onOpen={() => setToggleDrawerBottom(true)}
        isnotTitle={true}
        component={
          <CommunityMemberModel
            t={t}
            onClose={() => setToggleDrawerBottom(false)}
          />
        }
      />
      {/* UNIT SWITCHER */}
      <DialogDrawer
        open={unitSwitcher}
        header={t("Unit Switcher")}
        component={
          <>
            <Box sx={{ backgroundColor: "#fff" }}>
              <div className={classes.unitSwitcher}>
                {agreement.map((_) => {
                  return (<>
                    <Stack my={2}
                      onClick={() => clickedUnit(_)}
                      direction={"row"}
                      // spacing={"12px"}
                      alignItems={"center"}>
                      <Avatar variant="rounded" className={classes.unitAvatar}>
                        <PassUnits />
                      </Avatar>
                      <Box flexGrow={1}>
                        <Stack direction={"column"} spacing={"2px"}>
                          <Stack direction={"row"} spacing={"6px"} alignItems={"center"}
                            divider={<Box height={"15px"} width={"1px"} bgcolor={"#4e5a6b80"} />} >
                            {_?.name && <Typography className={classes.house} noWrap>{_?.name}</Typography>}
                            {_?.type && <Typography className={classes.house} noWrap>{_?.unit_type}</Typography>}
                          </Stack>
                          <Typography className={classes.units} noWrap>{_?.property_name}</Typography>
                        </Stack>
                      </Box>
                      <Radio
                        checked={data?.unit?.id === _?.id}
                        sx={{ color: "#CED3DD" }}
                      />
                    </Stack>
                    <Divider />
                  </>
                  )
                })}
              </div>
            </Box>
          </>
        }
        onClose={() => setUnitSwitcher(false)} />

      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={noService}
        header={" "}
        noService={true}
        drawerComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              mobile
              fullWidth
              onClose={() => setNoService(false)}
            />
          </>
        }
        dialogComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              fullWidth
              onClose={() => setNoService(false)}
            />
          </>
        }
        onClose={() => setNoService(false)}
      />
    </>
  );
};
export default withNamespaces("dashboard")(DashboardMain);
