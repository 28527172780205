import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// import moment from "moment/moment";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { InvoicePayNowList } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { DocumentViewer } from "../pdfviewer/index";
import InvoicePay from "./invoiceCardPay";
import { DashboardSimmer } from "../simmer/dashboardSimmer";

const useStyles = makeStyles((theme) => ({
  requestID: {
    borderRadius: theme.palette.borderRadius2,
    backgroundColor: "#dbf0f1",
    backgroundPositionX: "100%",
    color: "#091B29",
    minHeight: "139px",
    width: "155px",
    cursor: "pointer",
    "&.MuiCardContent-root": {
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "12px",
      paddingTop: "12px",
    },
  },
  CardContent: {
    borderRadius: theme.palette.borderRadius2,
    backgroundColor: "#EDE4FE",
    minHeight: "139px",
    width: "155px",
    marginBottom: "10px",

    "&.MuiCardContent-root": {
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "12px",
      paddingTop: "12px",
    },
  },
  general: {
    borderRadius: theme.palette.borderRadius2,
    backgroundColor: "#FEEAEA",
    backgroundPositionX: "100%",
    minHeight: "139px",
    width: "155px",
    cursor: "pointer",
    "&.MuiCardContent-root": {
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "12px",
      paddingTop: "12px",
    },
  },
  title: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().bold,
    //color: theme.palette.primary.contrastText,
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  body: {
    fontFamily: FontFamilySwitch().semiBold,
    display: "flex",
    alignItems: "center",
    marginTop: "6px",
    color: "#4E5A6B",
    fontSize: "0.75rem",
  },
  subBody: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    backgroundColor: "white",
    borderRadius: theme.palette.borderRadius,
    color: "black",
    padding: " 1px 7px",
  },
  titles: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  totalAmount: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },

  subTitle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  maintanceimg: {
    padding: "8px 10px 2px 10px",
    backgroundColor: "#119da4",
    borderRadius: "4px",
  },
  invoiceimg: {
    padding: "8px 10px 2px 10px",
    backgroundColor: "#7F48CE",
    borderRadius: "7px",
  },
  generalimg: {
    padding: "8px 10px 2px 10px",
    backgroundColor: "#f17360",
    borderRadius: "4px",
  },
  badge: {
    fontSize: "0.75rem",
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingBottom: "4px",
    paddingTop: "4px",
    backgroundColor: "white",
    borderRadius: "11px",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29"
  },
  type: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
  },
  btn: {
    padding: "10px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "none",
  },
  btn1: {
    padding: "10px",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "white",
    border: "1px solid #5078E1",
    color: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  cardDatas: {
    flexFlow: "wrap !important",
    alignItems: "center",
  },
  dot: {
    width: "8px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD"
  },
  titleText: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1.125rem"
  },
  amount: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1.125rem",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "79vh"
  }
}));

const ActionCarddashboard = ({
  cardData = [],
  type = "",
  onPay = () => false,
  names = "",
  t = () => false,
}) => {
  const [invoicepdf, setInvoicepdf] = React.useState({
    load: false,
    data: ""
  });
  const [showpdf, setShowpdf] = React.useState(false);
  const classes = useStyles(cardData);
  const [paymentoption, setPaymentoption] = React.useState(false);
  const [formopen, setformopen] = React.useState(false);
  const [openinvoice, setOpeninvoice] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const invoicedata = {
    id: cardData?.id,
    currency_master: {
      symbol: cardData.currency_symbol,
    },
    description: "",
    invoice_due_amount: cardData.OutsandingAmount,
    invoice_no: cardData.invoice_no,
    company: cardData?.company_id,
    currency_id: cardData?.currency_id,
  };
  const viewinvoice = (data) => {
    setInvoicepdf({ load: true })
    const payload = {
      invoice_id: cardData?.id,
    }
    NetworkCall(
      `${config.api_url}/invoice/template`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setInvoicepdf({ data: response?.data ?? "", load: false });
      })
      .catch((error) => {
        setInvoicepdf({ load: false })
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      });
  };
  React.useEffect(() => {
    if (showpdf) {
      viewinvoice()
    }
    // eslint-disable-next-line
  }, [showpdf])
  return (
    <>
      {type === "invoice" && (
        <CardContent
          className={classes.CardContent}
          onClick={() => {
            setOpeninvoice(true);
          }}
        >
          <Box display="flex">
            <Box>
              <Box className={classes.invoiceimg}>
                <img src="/images/invoiceCard.svg" alt="" />
              </Box>
            </Box>
            <Box height={"4px"} />
            {/* <Box flexGrow={1}></Box> */}
            <Box sx={{ marginInlineStart: "12px" }}>
              {cardData.is_Overdue && (
                <Typography className={classes.badge}>
                  {t("over_due")} {cardData?.over_due_days} {t("days")}
                </Typography>
              )}
            </Box>
          </Box>
          <Box height={"8px"} />
          <Typography
            style={{
              fontSize: "0.75rem",
              color: "#4E5A6B",
              fontFamily: FontFamilySwitch().semiBold,
              wordWrap: "break-word",
            }}
          >
            {t("invoice")}
          </Typography>
          <Box height={"8px"} />
          <Typography className={classes.amount}>
            {cardData.currency_symbol +
              Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
              }).format(cardData.OutsandingAmount)}
          </Typography>
          <Box height={"4px"} />
          <Box className={classes.cardDatas}>
            <Stack direction={"column"} spacing={"6px"}>
              <Typography className={classes.type} noWrap>
                {cardData.invoice_type}
              </Typography>
              <Stack direction={"row"} divider={<Divider orientation="vertical" flexItem />} spacing={1} >
                <Typography noWrap className={classes.type}>
                  {cardData.invoice_no}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </CardContent>
      )}
      {type === "maintenance" && (
        <CardContent className={classes.requestID}>
          <Box display="flex">
            <Box>
              <Box className={classes.maintanceimg}>
                <img src="/images/generalCard.svg" alt="" />
              </Box>
            </Box>
            {/* <Box flexGrow={1}></Box> */}
            <Box>
              <Typography sx={{ marginInlineStart: "12px" }} className={classes.badge}>
                {cardData?.status}
              </Typography>
            </Box>
          </Box>

          <Box height={"8px"} />

          <Typography
            style={{
              fontSize: "0.75rem",
              color: "#4E5A6B",
              fontFamily: FontFamilySwitch().semiBold,
              wordWrap: "break-word",
            }}
          >
            {t("maintenance")}
          </Typography>

          <Box height={"8px"} />
          <Typography className={classes.titleText} noWrap>
            {cardData.subject}
          </Typography>
          <Box height={"4px"} />
          <Box className={classes.cardDatas}>
            <Stack direction={"column"} spacing={"6px"}>
              <Typography className={classes.type}>
                {cardData.category}&nbsp;
              </Typography>
              <Typography noWrap className={classes.type}>
                {cardData.maintenance_request_no}
              </Typography>
            </Stack>
          </Box>
        </CardContent>
      )}
      {type === "general" && (
        <CardContent className={classes.general}>
          <Box display="flex">
            <Box>
              <Box className={classes.generalimg}>
                <img src="/images/maintanaceCard.svg" alt="" />
              </Box>
            </Box>
            {/* <Box flexGrow={1}></Box> */}
            <Box>
              <Typography sx={{ marginInlineStart: "12px" }} className={classes.badge}>
                {cardData?.status}
              </Typography>
            </Box>
          </Box>
          <Box height={"8px"} />

          <Typography
            style={{
              fontSize: "0.75rem",
              color: "#4E5A6B",
              fontFamily: FontFamilySwitch().semiBold,
              wordWrap: "break-word",
            }}
          >
            {" "}
            {t("general")}
          </Typography>

          <Box height={"4px"} />
          <Typography className={classes.titleText} noWrap>
            {cardData.category}
          </Typography>
          <Box height={"8px"} />
          <Box className={classes.cardDatas}>
            <Stack direction={"column"} spacing={"6px"}>
              <Typography className={classes.type}>
                {cardData.subject}  &nbsp;
              </Typography>
              <Typography noWrap className={classes.type}>
                {cardData.general_request_no}
              </Typography>
            </Stack>
            {/* <Box>
              <Typography className={classes.type} noWrap>
                {cardData.subject}  &nbsp; &#8226;  &nbsp;{cardData.general_request_no}
              </Typography>
            </Box> */}
          </Box>
        </CardContent>
      )}
      <Hidden smDown>
        <Dialog
          maxWidth="sm"
          className={classes.dialog}
          fullWidth
          open={openinvoice}
          onClose={() => {
            setOpeninvoice(!openinvoice);
          }}
        >
          <Box
            display="flex"
            p={1}
            style={{ borderBottom: "1px solid #c1c1c1" }}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.titles}>
                {type === "invoice" && t("invoice")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setOpeninvoice(!openinvoice);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {type === "invoice" && (
            <>
              <Grid xs={12} style={{ padding: "0px 12px 12px 12px" }}>
                <InvoicePayNowList
                  InvoicePay={invoicedata}
                  dashboard={true}
                  delete={true}
                  type="dashboard"
                  viewinvoice={() => setShowpdf(true)}
                />
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    {/* <Grid item xs={12}>
                      <Typography
                        className={classes.titles}
                        style={{ marginTop: "8px" }}
                      >
                        {t("bill_summary")}
                      </Typography>
                    </Grid> */}

                    {/* <Grid
                      container
                      alignItems="center"
                      style={{ padding: "4px 8px 8px 8px" }}
                    >
                      <Grid item xs={6}>
                        <Typography className={classes.subTitle}>
                          {t("invoice_amount")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ float: "right" }}>
                          <Typography className={classes.subTitle}>
                            {cardData.currency_symbol +
                              Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 0,
                              }).format(Math.round(cardData.OutsandingAmount))}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid> */}
                    <Grid
                      container
                      alignItems="center"
                      style={{
                        display: "flex",
                        borderBottom: "2px dashed #CED3DD",
                        borderTop: "2px dashed #CED3DD",
                        padding: "4px 8px 4px 8px",
                      }}
                    >
                      <Grid item xs={6}>
                        <Typography className={classes.totalAmount}>
                          {t("total_amount_to_pay")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ float: "right" }}>
                          <Typography className={classes.totalAmount}>
                            {cardData.currency_symbol +
                              Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 0,
                              }).format(Math.round(cardData.OutsandingAmount))}
                          </Typography>
                        </div>
                        <br />
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
              <Box p={1}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={classes.btn1}
                      onClick={() => setformopen(true)}
                      fullWidth
                    >
                      {t("already_paid")}
                    </Button>
                  </Grid>
                  {/* <Grid item lg={1}></Grid> */}
                  <Grid item xs={6}>
                    <Button
                      disabled
                      variant="contained"
                      className={classes.btn}
                      fullWidth
                      onClick={() => setPaymentoption(true)}
                    >
                      {t("pay_now")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          maxWidth="sm"
          fullWidth
          anchor="bottom"
          open={openinvoice}
          onClose={() => {
            setOpeninvoice(!openinvoice);
          }}
        >
          <Box
            display="flex"
            p={1}
            style={{ borderBottom: "1px solid #c1c1c1" }}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.titles}>
                {type === "invoice" && t("Invoices")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setOpeninvoice(!openinvoice);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {type === "invoice" && (
            <>
              <Grid xs={12} style={{ padding: "0px 12px 12px 12px" }}>
                <InvoicePayNowList
                  InvoicePay={invoicedata}
                  dashboard={true}
                  delete={true}
                  type="dashboard"
                  viewinvoice={() => setShowpdf(true)}
                />
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    {/* <Grid item xs={12}>
                      <Typography
                        className={classes.titles}
                        style={{ marginTop: "8px" }}
                      >
                        {t("bill_summary")}
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: "4px 8px 8px 8px" }}
                    >
                      <Grid item xs={6}>
                        <Typography className={classes.subTitle}>
                          {t("invoice_amount")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ float: "right" }}>
                          <Typography className={classes.subTitle}>
                            {cardData.currency_symbol +
                              Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 0,
                              }).format(Math.round(cardData.OutsandingAmount - (cardData.invoice_total_tax_amount ?? 0)))}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid> */}
                    <Grid
                      container
                      alignItems="center"
                      style={{ padding: "4px 8px 8px 8px" }}
                    >
                      <Grid item xs={6}>
                        <Typography className={classes.subTitle}>
                          {t("tax_amount")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ float: "right" }}>
                          <Typography className={classes.subTitle}>
                            {cardData.currency_symbol +
                              Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 0,
                              }).format(Math.round(cardData.invoice_total_tax_amount))}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid> */}
              
                    <Grid
                      container
                      alignItems="center"
                      style={{
                        display: "flex",
                        borderBottom: "2px dashed #CED3DD",
                        borderTop: "2px dashed #CED3DD",
                        padding: "4px 8px 4px 8px",
                      }}
                    >
                      <Grid item xs={6}>
                        <Typography className={classes.totalAmount}>
                          {t("total_amount_to_pay")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ float: "right" }}>
                          <Typography className={classes.totalAmount}>
                            {cardData.currency_symbol +
                              Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 0,
                              }).format(Math.round(cardData.OutsandingAmount))}
                          </Typography>
                        </div>
                        <br />
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
              <Box p={1}>
                <Grid container spacing={1}>
                  <Grid item lg={6} xs={6}>
                    <Button
                      variant="contained"
                      className={classes.btn1}
                      onClick={() => setformopen(true)}
                      fullWidth
                    >
                      {t("already_paid")}
                    </Button>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <Button
                      disabled
                      variant="contained"
                      className={classes.btn}
                      fullWidth
                      onClick={() => setPaymentoption(true)}
                    >
                      {t("pay_now")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Drawer>
      </Hidden>
      {/* invoice */}
      <Hidden smDown>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={showpdf}
          className={classes.dialog}
          onClose={() => setShowpdf(false)}
        >
          <Box
            display="flex"
            p={1}
            alignItems="center"
            className={classes.titleroot}
          >
            <Box flexGrow={1}>
              <Typography className={classes.bottomTitle}>
                {names?.invoice ?? "Invoice"}
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={() => {
                setInvoicepdf({ data: "", load: false })
                setShowpdf(false)
              }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <div style={{ height: size?.height - 115 }}>
            {invoicepdf?.load ?
              <DashboardSimmer />
              :
              invoicepdf?.data?.length > 0 ? (
                <DocumentViewer url={`data:application/pdf;base64,${invoicepdf?.data}`} />
              ) : (
                <>
                  <center className={classes.noData}>
                    <Typography className={classes.bottomTitle}>
                      {"No Data"}
                    </Typography></center></>
              )}

          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          maxWidth="sm"
          anchor="bottom"
          fullWidth
          open={showpdf}
          className={classes.drawer}
          onClose={() => setShowpdf(false)}
        >
          <Box
            display="flex"
            p={1}
            alignItems="center"
            className={classes.titleroot}
          >
            <Box flexGrow={1}>
              <Typography className={classes.bottomTitle}>
                {names?.invoice ?? t("invoice")}
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={() => {
                setInvoicepdf({ data: "", load: false })
                setShowpdf(false)
              }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <div style={{ height: size?.height - 115 }}>
            {invoicepdf?.load ?
              <DashboardSimmer />
              :
              invoicepdf?.data?.length > 0 ? (
                <DocumentViewer url={`data:application/pdf;base64,${invoicepdf?.data}`} />
              ) : (
                <>
                  <center className={classes.noData}>
                    <Typography className={classes.bottomTitle}>
                      {"No Data"}
                    </Typography></center></>
              )}
          </div>

        </Drawer>
      </Hidden>
      {paymentoption || formopen ? (
        <InvoicePay
          paymentoption={paymentoption}
          onClose={() => setPaymentoption(false)}
          formopen={formopen}
          onClose1={() => setformopen(false)}
          Outstanding_amount={cardData.OutsandingAmount}
          amount={cardData.OutsandingAmount}
          invoices={[cardData?.id]}
          currency_symbol={cardData.currency_symbol}
          company={cardData?.company_id}
          currency_id={cardData?.currency_id}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default withNamespaces("invoice")(ActionCarddashboard);
