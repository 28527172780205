import { Close } from "@mui/icons-material";
import { Box, Button, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import QrReader from "react-qr-reader";
import { withBottombar } from "../../HOCs";
import { EllipseIcon } from "../../assets/ellipseIcon";
import { UnitIcon } from "../../assets/unitIcon";
import { DialogDrawer } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { GET_UNITS_BY_AGREEMENT_ID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";
import Slider from "react-slick";
import ImageAndPdfViewer from "../../components/assetViewer/imageAndPdfViewer";

const ScanQR = (props) => {
    const { t = () => false, handleTopBarState, unit = "", setUnit = () => false, product = "", setProduct = () => false,goBack=()=>false } = props
    const classes = useStyles()
    const alert = React.useContext(AlertContext)
    const size = useWindowDimensions()
    const backdrop = React.useContext(BackdropContext);
    const [itemDetails, setItemDetails] = React.useState([])
    const [offset, setOffset] = React.useState(0);
    const [unitList, setUnitList] = React.useState([])
    const [imageViewer, setImageViewer] = React.useState(false)
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
    };
    // const [mappingId, setMappingId] = React.useState("")
    React.useEffect(() => {
        handleTopBarState({
            text: t("Scan"),
            customBackHandle:()=>goBack()
        })
        getAllunitsByuserId()
        // eslint-disable-next-line
    }, []);
    const getItemDetails = (data, unit) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        const payload = {
            "mapping_id": data,
            "unit_id": unit
        };
        NetworkCall(
            `${config.api_url}/request/scan_item`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                setItemDetails(response?.data?.data)
                setProduct({ ...product, bool: true, is_product: true, selectedProduct: response?.data?.data })
            })
            .catch((error) => {
                if (error.response) {
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                    });
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error?.response?.data?.error?.message?.msg).length !== 0 ? error?.response?.data?.error?.message?.msg : "Something went wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                    });
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("SomethingWentwrong"),
                    });
                }
            });
    }
    const getAllunitsByuserId = (offset = 0, scroll = false) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        NetworkCall(
            `${config.api_url}/agreement-unit-info/switcher`,
            NetWorkCallMethods.post,
            {},
            null,
            true,
            false
        )
            .then((response) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                getUnit(response?.data?.data?.map((val) => { return val?.id }), offset, scroll)
            })
            .catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                if (error?.code === 503) {
                } else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("SomethingWentwrong"),
                    });
                }
            });
    };
    const getUnit = (value, offset = 0, scroll = false) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        const payload = {
            query: GET_UNITS_BY_AGREEMENT_ID,
            variables: {
                id: value,
                offset: offset,
                limit: 10
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                if (scroll) {
                    setUnitList(unitList?.concat(response?.data?.data?.agreement_units));
                    setUnit({ ...unit, selectedUnit: { ...unitList?.[0]?.unit, agreement_id: unitList?.[0]?.agreement_id } })
                }
                else {
                    setUnitList(response?.data?.data?.agreement_units);
                    setUnit({ ...unit, selectedUnit: { ...response?.data?.data?.agreement_units?.[0]?.unit, agreement_id: response?.data?.data?.agreement_units?.[0]?.agreement_id } })
                }

            })
            .catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };
    const handleScan = (data) => {
        if (data) {
            // setMappingId(data)
            getItemDetails(data, unit?.selectedUnit?.value)
        }
        // setIsScanned(true)

    }
    const goToNextPage = () => {
        setProduct({ ...product, bool: false, is_product: false, is_unit: false })
        props?.next(1)
    }
    const handleSelectUnit = (val) => {
        setUnit({ ...unit, bool: false, selectedUnit: { ...val?.unit, agreement_id: val?.agreement_id } })
        // if (mappingId !== "") {
        //     getItemDetails(mappingId, val?.unit?.value)
        // }
    }

    const handleError = (data) => {
        console.log("error", data)
    }
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getAllunitsByuserId(offset + 10, true);
    }
    const openImageViewer = () => {
        setImageViewer(true)
    }
    const closeImageViewer = () => {
        setImageViewer(false)
    }
    return (
        <Container maxWidth="sm" style={{ padding: 0 }}>
            <Box className={classes.scanQrBox} style={{ height: size?.height - 55 }}>
                <Box className={classes.qrSection}>
                    <center>
                        <Grid item xs={12} style={{ overflow: "auto" }}>
                            <QrReader
                                delay={500}
                                onError={handleError}
                                onScan={handleScan}
                                className={classes.qrcode}
                            />
                            <Box mt={2}>
                                <Typography className={classes.serviceTitle}>{t("Scan QR Code")}</Typography>
                            </Box>
                        </Grid>
                    </center>
                </Box>
                <Box className={classes.unitDetailsBox}>
                    <Box className={classes.unitBox} display={"flex"} justifyContent={"space-between"} alignItems="center">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <UnitIcon />
                            <Box style={{ marginInlineStart: "8px" }}>
                                <Typography className={classes.title}>{unit?.selectedUnit?.name}</Typography>
                                <Typography className={classes.subTitle}>{unit?.selectedUnit?.unit_no}</Typography>
                            </Box>
                        </Stack>
                        <Typography className={classes.changetext} onClick={() => setUnit({ ...unit, bool: true })}>{t("Change")}</Typography>
                    </Box>
                </Box>
            </Box>
            <DialogDrawer
                open={unit?.bool}
                onClose={() => setUnit({ ...unit, bool: false })}
                isnotTitle={true}
                padding={0}
                overflow={"overlay"}
                component={
                    <Box>
                        <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems="center">
                            <Typography className={classes.unitSwitchTitle}>{t("Unit Switcher")}</Typography>
                            <Close color="#4E5A6B" onClick={() => setUnit({ ...unit, bool: false })} />
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <Box className={classes.unitSectionBox} p={2}>
                                <InfiniteScroll
                                    dataLength={unitList?.length ?? 10}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    height={size?.height - 200}
                                >
                                    {unitList?.map((val, i) => {
                                        return (
                                            <>
                                                <Box display="flex" justifyContent={"space-between"} alignItems={"center"} onClick={() => handleSelectUnit(val)}>
                                                    <Stack direction={"row"} alignItems="center">
                                                        <Box style={{ marginInlineEnd: "8px" }}><UnitIcon /></Box>
                                                        <Box>
                                                            <Typography className={classes.unitTitle}>{val?.unit?.name}</Typography>
                                                            <Box display="flex" alignItems="center">
                                                                <Typography className={classes.subTitle} noWrap>{val?.unit?.unit_no}</Typography>
                                                                <Box className={classes.unitDot}></Box>
                                                                <Typography className={classes.propertyName} noWrap>{val?.unit?.property?.name}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                    {unit?.selectedUnit?.value === val?.unit?.value ?
                                                        <Box  style={{ cursor: "pointer" }}>
                                                            <EllipseIcon color="#5078E1" /></Box>
                                                        : <Box  style={{ cursor: "pointer" }}><EllipseIcon /></Box>}

                                                </Box>
                                                {unitList?.length - 1 !== i &&
                                                    <Box mt={1.5} mb={1.5}><Divider /></Box>
                                                }
                                            </>
                                        )
                                    })}
                                </InfiniteScroll>
                            </Box>
                        </Box>
                    </Box>
                }
            />
            <DialogDrawer
                open={product?.bool}
                onClose={() => setProduct({ ...product, bool: false, is_product: false, is_unit: false })}
                isnotTitle={true}
                padding={0}
                overflow={"overlay"}
                component={
                    <Box>
                        <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems="center">
                            <Typography className={classes.unitSwitchTitle}>{product?.is_product ? t("Asset Details") : t("Unit Details")}</Typography>
                            <Close color="#4E5A6B" onClick={() => setProduct({ ...product, bool: false, is_product: false, is_unit: false })} />
                        </Box>
                        <Box p={2}>
                            <center>

                                {itemDetails?.assets?.length > 0 &&
                                    <Slider {...settings}>
                                        {JSON.parse(itemDetails?.assets)?.map((x) => {
                                            return (

                                                <Box mb={1.5} onClick={() => openImageViewer()}>
                                                    <img src={x?.url} alt="product asset" className={classes.productImage} />
                                                </Box>

                                            )
                                        })}
                                    </Slider>
                                }
                                <Box className={classes.itemBox}>
                                    <Typography className={classes.itemCondition}>{itemDetails?.item_condition}</Typography>
                                </Box>
                                <Box mt={1.5}>
                                    <Typography className={classes.itemName}>{itemDetails?.item_name}</Typography>
                                </Box>
                            </center>
                            <Box mt={2} height={"180px"}>
                                <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography className={classes.productTitle}>{t("Manufacturer")}</Typography>
                                    <Typography className={classes.productValue}>{itemDetails?.manufacturer ?? "-"}</Typography>
                                </Box>
                                <Box mt={1.5} mb={1.5}><Divider /></Box>
                                <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography className={classes.productTitle}>{t("Serial Number")}</Typography>
                                    <Typography className={classes.productValue}>{itemDetails?.serial_number ?? "-"}</Typography>
                                </Box>
                                <Box mt={1.5} mb={1.5}><Divider /></Box>
                                <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography className={classes.productTitle}>{t("Model Number")}</Typography>
                                    <Typography className={classes.productValue}>{itemDetails?.model_number ?? "-"}</Typography>
                                </Box>
                                {itemDetails?.location_id !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes.productTitle}>{t("Location")}</Typography>
                                            <Typography className={classes.productValue}>{itemDetails?.location_name ?? "-"}</Typography>
                                        </Box>
                                    </>
                                }
                                {itemDetails?.last_service_date !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes.productTitle}>{t("Last Service")}</Typography>
                                            <Typography className={classes.productValue}>{moment(itemDetails?.last_service_date)?.format("DD MMM YYYY")}</Typography>
                                        </Box>
                                    </>
                                }
                            </Box>

                        </Box>
                        <Box p={2} mt={1}>
                            <Button variant="contained" fullWidth className={classes.raiseRequestbtn} onClick={() => goToNextPage()}>{t("Raise Request")}</Button>
                        </Box>
                    </Box>
                }
            />
            {/* Image Viewer */}
            {imageViewer &&
                <DialogDrawer
                    open={imageViewer}
                    onClose={() => closeImageViewer()}
                    isnotTitle={true}
                    padding={0}
                    overflow={"overlay"}
                    component={
                        <ImageAndPdfViewer
                            title={t("Assets")}
                            assets={JSON.parse(itemDetails?.assets)?.length > 0 ? JSON.parse(itemDetails?.assets)?.map((val) => {
                                return {
                                    meta_data: {
                                        file_type: "image",
                                    },
                                    file_url: val?.url,
                                };
                            }) : []}
                            onClose={() => closeImageViewer()}
                        />
                    }
                />
            }
        </Container>
    )
}
const props = {
    top_bar: "title_bar",
}
export default withBottombar(withNamespaces("maintance")(ScanQR), props)